









import PunishMessage from "@/components/Modules/punish-actions/components/PunishMessage.vue";
import { NotifyAction } from "@/includes/logic/Modules/models/PunishActions";

import { Vue, Component, VModel } from 'vue-property-decorator';

@Component({
  components: {
    PunishMessage
  }
})
export default class NotifyActionView extends Vue {
  @VModel() model!: NotifyAction

}
